<script>
import Navbar from "@/components/navbar2";
import Services from "@/components/privacy-terms";
import Footer from "@/components/footer";

/**
 * index-8 component
 */
export default {
  name: "index-8",
  components: {
    Navbar,
    Services,
    Footer,
  },
};
</script>

<template>
  <div>
    <Navbar />
    <div>
      <!--START HOME-->
      <section class="section section-lg home-half"
        id="home"
        :style="{'background-image': 'url(' + require('@/assets/iStock-544756566.jpg') + ')' , 'background-size': 'cover', 'background-position': 'center'}"
      ><div class="bg-overlay"></div>
      </section>
      <Services />
      <Footer />
    </div>
  </div>
</template>
<script>
export default {};
</script>

<template>
<div>
  <!--START SERVICES-->
    <section class="section pt-5" id="privacy">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 offset-lg-2">
                    <h1 class="section-title text-center margin-t-50">Best Bonita Cleaning Privacy Policy</h1>
                    <div class="section-title-border margin-t-20"></div>
                </div>
            </div>
            <div class="row margin-t-30">
                <div class="col-lg-12 margin-t-20">
                    <div class="services-box">
                    <p class="padding-t-15">
<span class="heading">We keep your information safe.</span>
<br>
This Privacy Policy governs the manner in which Best Bonita Cleaning collects, uses, maintains and discloses information collected from users (each, a “User”) of the https://bonita.cleaning/ website (“Site”). This privacy policy applies to the Site and all products and services offered by Best Bonita Cleaning.
<br>
Personal identification information:
We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site, place an order, subscribe to the newsletter, respond to a survey, fill out a form, and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.
<br>
Non-personal identification information:
We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers utilized and other similar information.
<br>
Web browser cookies:
Our Site may use “cookies” to enhance User experience. User’s web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.
<br>
How we use collected information:<br>
Best Bonita Cleaning may collect and use Users personal information for the following purposes:<br>
1. To improve customer service.<br>
Information you provide helps us respond to your customer service requests and support needs more efficiently.<br>
2. To personalize user experience.<br>
We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.<br>
3. To improve our Site.<br>
We may use feedback you provide to improve our products and services.<br>
4. To share your information with third parties.<br>
We may share or sell information with third parties for marketing or other purposes.<br>
5. To send periodic emails<br>
We may use the email address to send User information and updates pertaining to their order. It may also be used to respond to their inquiries, questions, and/or other requests. If User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email or User may contact us via our Site.
<br>
How we protect your information:<br>
We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.
Sensitive and private data exchange between the Site and its Users happens over a SSL secured communication channel and is encrypted and protected with digital signatures. Our Site is also in compliance with PCI vulnerability standards in order to create as secure of an environment as possible for Users.
<br>
Sharing your personal information:<br>
We may use third party service providers to help us operate our business and the Site or administer activities on our behalf, such as sending out newsletters or surveys. We may share your information with these third parties for those limited purposes provided that you have given us your permission.
<br>
Third party websites:<br>
Users may find advertising or other content on our Site that link to the sites and services of our partners, suppliers, advertisers, sponsors, licensors and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site. In addition, these sites or services, including their content and links, may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites which have a link to our Site, is subject to that website’s own terms and policies.
<br>
Advertising:<br>
Ads appearing on our site may be delivered to Users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile non personal identification information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This privacy policy does not cover the use of cookies by any advertisers.
<br>
Compliance with children’s online privacy protection act:<br>
Protecting the privacy of the very young is especially important. For that reason, we never collect or maintain information at our Site from those we actually know are under 13, and no part of our website is structured to attract anyone under 13.
<br>
Changes to this privacy policy:<br>
Best Bonita Cleaning has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
<br>
Your acceptance of these terms:<br>
By using this Site, you signify your acceptance of this policy and terms of service. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.
<br>
Contacting us:<br>
If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at: contact@bonita.cleaning  
                </p>
                </div>
                </div>
            </div>
        </div>
    </section>
    <section class="section pt-5" id="terms">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 offset-lg-2">
                <h1 class="section-title text-center margin-t-50">Best Bonita Cleaning Terms Of Services</h1>
                <div class="section-title-border margin-t-20"></div>
                </div>
            </div>
            <div class="row margin-t-30">
                <div class="col-lg-12 margin-t-20">
                    <div class="services-box">
                        <p class="padding-t-15">
                        We reserve the right to cancel any service, for any reason.<br>
We do not assume liability for health and safety of your pets, you are in the best position to ensure their safety.
<br>
We do carry insurance for damage or breakage caused by our cleaners. We are not liable for damage that is caused by normal wear and tear, improper installation of an item(s), or artwork, collectibles or family heirlooms valued over $200 and that is not disclosed during the time of making the appointment. If any damage or perceived damage is noticed after the cleaners have left, the customer must notify Best Bonita Cleaning within 24 hours of any problem that may have occurred at the address during that day and time of the scheduled cleaning. We will do our best to resolve the problem and come up with a reasonable solution. These items include but are not limited to the following examples: 
<br>
1. Carpet & Rug Snags - Carpet snags are the result of exposed loops caused by normal wear and tear, moving furniture, etc. which are snagged by a vacuum’s roller brush. We use professional vacuums that are set to industry standards. In order to limit snags or fraying, we will try our best not to go near the bad areas of carpeting/rugs. 
<br>
2. Broken Blinds - Customers should be aware that there are some inherent risks each time your blinds are cleaned. Blinds will become brittle from daily exposure to the sun and aging, and strings/chords will weaken over time resulting in breaks. 
<br>
3. Improperly hung pictures/decorations/mirrors - If these items are securely/properly attached to the wall, they should not fall when the item is dusted/wiped. 
<br>
4. Artwork, Collectibles or Family Heirlooms valued over $200 - These items are expensive and/or impossible to replace. In that case, we do not assume the risk of cleaning such items.  It is the customer’s responsibility to inform, in writing, of any item(s) that fall into this category. 
<br>
5. Woodwork, Vintage/Antique Wood Furniture, Un-finished & Furniture with Special Instructions - Please let us know during your booking if your woodwork, wooden furniture or vintage wooden furniture is not sealed or is made of another material other than actual wood. Please discuss your furniture care needs with specifications as per item if they need special attention in anyway. If you would like to supply your own cleaning product(s) for your specific piece(s) of furniture, leather furniture, pianos, etc., please notify us via email or text of the preferred product(s) you will provide us with. 
<br>
We need electricity and water!  If your utilities are turned off, we will gladly reschedule you to when they are back on.
<br>
We have 3-step ladders which is sufficient to reach most 1st floor windows, ceiling fans, and upper shelves.  Generally do not climb higher than that - for liability reasons.  
<br>
If the home is in bad shape - broken glass, mountains of clutter, or "hoarder's living space" - we can most likely bring it to a livable/ habitable standard.  However, the end result will not be comparable with the level of cleanliness we can achieve on a standard home.  Because of that, we will discuss this issue before starting the job and upon arrival onsite. By using our services you agree to allow Best Bonita Cleaning to photograph or sometimes record before and after for potential sharing and strategizing within our company on how to best approach the cleaning. We will neither keep nor distribute the photos for any other reason without your permission.
<br>
We will not clean walls, ceilings, or animal waste. Walls can be easily damaged and they are a liability as well as ceilings. Please also note that we do not perform laundry services if the washing and drying machines are not inside the customer's home.
<br>
Please note that we will not render our services if there is construction going on or any other service being performed while our cleaning crew is at customer's homes. This is due to the fact that the customer's home will most likely get dirty again even after our services have been thoroughly performed.
<br>
We DO NOT process refund of payments after services have been rendered. However, Best Bonita Cleaning agrees to come back to a customer's home for a free re-clean within 3 days after service is rendered if a customer is dissatisfied with our service.
<br>
We, at Best Bonita Cleaning Services, love to hear what you have to say about our company and we encourage honest feedback; however we will not accept bribery. We have absolute ZERO tolerance for any individual or customer who offers a "Great Review" or threatens to write a "Bad Review" in exchange of discounts, free cleanings, or even money from our part. We will "fire" every customer who engages in this type of behavior.
<br>
You agree to defend, indemnify and hold harmless Best Bonita Cleaning and its subsidiaries, agents, licensors, managers, and other affiliated companies, and their employees, owners, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees) arising from: (i) your use of any cleaning services received by you and performed by Best Bonita Cleaning's representatives; (ii) any alleged or actual loss or damage to property (including your residence), resulting from the performance of Best Bonita Cleaning's cleaning services.
<br>
We may revise these terms of service at any time without notice. By using our services you are agreeing to be bound by the then current version of these Terms of Service.
<br>
TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, Best Bonita Cleaning DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE CLEANING SERVICES, INCLUDING THE CLEANING SERVICES PERFORMED BY Best Bonita Cleaning’ REPRESENTATIVES, OR ANY LOSSES OR DAMAGES THAT MAY RESULT THEREFROM. THE SERVICE, INCLUDING OUR CLEANINGS, ARE PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS. USE OF THE SERVICE IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE IS PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, WORKMANLIKE QUALITY, OR NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, Best Bonita Cleaning, ITS SUBSIDIARIES, AND ITS LICENSORS DO NOT WARRANT THAT THE SERVICE IS ACCURATE, RELIABLE OR CORRECT; THAT THE SERVICE WILL MEET YOUR REQUIREMENTS; OR THAT THE SERVICE WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
</template>
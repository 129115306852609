<script>
export default {
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    toggleSwitcher() {
      this.isVisible = !this.isVisible;
    },

    /**
     * Onclick color change
     * @param theme theme color
     */
    setTheme(theme) {
      document
        .getElementById("color-opt")
        .setAttribute("href", "./css/colors/" + theme + ".css");
    },
  },
};
</script>


<template>
  <div>
    <!--START FOOTER ALTER-->
    <div class="footer-alt">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="float-left pull-none">
              <p class="copy-rights text-muted">{{ new Date().getFullYear()}} Bonita.Cleaning
              <a href="/privacy#terms">Terms of services</a>
              <a href="/privacy">Your privacy</a>
              </p>
            </div>
            <div class="float-right pull-none">
              <img src="@/assets/images/email.png" alt="Bonita.Cleaning Email" height="36" />
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>